<template>
  <div style="width: 100%;height: calc(100% - 28px);overflow: hidden;">
    <div style="width: 100%;height: 100%;">
      <div id="search" class="search-container">
        <el-form :inline="true" @submit.native.prevent>
          <el-form-item>
            <el-input v-model="searchForm.code" placeholder="代码或名称" clearable @keyup.enter.native="searHqhisList()">
            </el-input>
          </el-form-item>

          <el-form-item>
            <el-button @click="searHqhisList">搜索</el-button>
          </el-form-item>
          <el-form-item>
            <el-popconfirm title="确定加入我的收藏吗？" @confirm="addFavorite(null)">
              <el-button slot="reference">收藏</el-button>
            </el-popconfirm>
          </el-form-item>
        </el-form>
      </div>

      <div id="table" class="mini-table-container">
        <!-- <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border stripe
		@selection-change="handleSelectionChange">  size="mini"   -->
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" :height="tableHeight"
          :header-row-style="{height:'30px'}" :header-cell-style="{background:'#f5f7fa',padding:'0px'}"
          :row-style="rowStyle" :cell-style="{padding: 0}" @selection-change="handleSelectionChange" style="width: 100%"
          border stripe>

          <el-table-column type="selection" width="40"> </el-table-column>
          <el-table-column label="序号" align="center" width="50">
            <template slot-scope="scope">{{ (current - 1) * size + scope.$index + 1 }}</template>
          </el-table-column>

          <el-table-column prop="aglistId" label="id" v-if="false"></el-table-column>
          <el-table-column prop="code" label="代码" align="center" width="80"></el-table-column>
          <el-table-column prop="name" label="名称" align="center" width="90"></el-table-column>
          <el-table-column prop="stype" label="类型" align="center" width="90"></el-table-column>
          <el-table-column prop="hsgt" label="沪深港通" align="center" width="78"></el-table-column>
          <el-table-column prop="bk" label="板块" align="center" width="78"></el-table-column>
          <!-- <el-table-column prop="cfg" label="成分股" align="center" width="90"></el-table-column> -->
          <el-table-column prop="roe" label="ROE" align="center" width="86" :formatter="twoDotFmt"></el-table-column>
          <el-table-column prop="zgb" label="总股本(万股)" align="center" width="106" :formatter="wanFormatter">
          </el-table-column>
          <el-table-column prop="ltgb" label="流通股" align="center" width="100" :formatter="wanFormatter">
          </el-table-column>
          <el-table-column prop="ltsz" label="流通市值" align="center" width="80" :formatter="yiFormatter">
          </el-table-column>
          <el-table-column prop="zsz" label="总市值亿" align="center" width="80" :formatter="yiFormatter"></el-table-column>
          <el-table-column prop="ssdate" label="上市日期" align="center" width="110"></el-table-column>
          <el-table-column prop="hashq" label="存在行情" align="center" width="78"></el-table-column>

          <el-table-column prop="icon" width="140px" label="操作">
            <template slot-scope="scope">
              <el-button type="text" :disabled="scope.row.hashq == '是' ? true : false" @click="addGpHq(scope.row.code)">
                加入行情</el-button>
              <el-button type="text" @click="editHandle(scope.row.id)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          layout="total, sizes, prev, pager, next, jumper" :page-sizes="[20, 50, 150, 300,500]" :current-page="current"
          :page-size="size" :total="total"></el-pagination>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "Aginfo",
  data() {
    return {
      searchForm: {},
      // delBtlStatu: true,
      total: 0,
      defaultSize: 50,
      defaultCurrent: 1,
      size: this.defaultSize,
      current: this.defaultCurrent,
      // dialogVisible: false,
      // editForm: {
      // },
      tableData: [],
      favrtAglstId: [],
      multipleSelection: [],
      tableHeight: undefined,
      //#region
      // editFormRules: {
      // 	username: [
      // 		{ required: true, message: '请输入用户名称', trigger: 'blur' }
      // 	],
      // 	email: [
      // 		{ required: true, message: '请输入邮箱', trigger: 'blur' }
      // 	],
      // 	statu: [
      // 		{ required: true, message: '请选择状态', trigger: 'blur' }
      // 	]
      // },

      // multipleSelection: [],

      // roleDialogFormVisible: false,
      // defaultProps: {
      // 	children: 'children',
      // 	label: 'name'
      // },
      // roleForm: {},
      // roleTreeData: [],
      // treeCheckedKeys: [],
      // checkStrictly: true
      //#endregion
    };
  },
  created() {
    this.tableHeight = window.innerHeight - 60 - 28 - 36 - 32 - 10;
    this.getAgInfo();
    // this.$axios.get("/sys/role/list").then(res => {
    // 	this.roleTreeData = res.data.data.records
    // })
  },
  methods: {
    rowStyle() {
      return { height: "20px" };
    },

    handleSelectionChange(val) {
      console.log("勾选")
      console.log(val)
      this.multipleSelection = val;
    },

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.size = val;
      this.getAgInfo();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.current = val;
      this.getAgInfo();
    },
    twoDotFmt(row, column, cellValue, index) {
      //数值取两位小数
      return cellValue.toFixed(2);
    },
    yiFormatter(row, column, cellValue, index) {
      //数值以 亿 为单位  Math.round(7/2)
      return Math.round(cellValue / 100000000);
    },
    wanFormatter(row, column, cellValue, index) {
      //数值以 万 为单位
      return Math.round(cellValue / 10000);
    },

    searHqhisList() {
      this.current = this.defaultCurrent;
      this.size = this.defaultSize;
      this.getAgInfo();
    },

    addFavorite(id) {
      if (id) {
        this.favrtAglstId.push(id)
      } else {
        this.multipleSelection.forEach(row => {
          this.favrtAglstId.push(row.aglistId)
        })
      }
      // console.log("==========+++++++===========");
      // console.log(this.favrtAglstId)
      // console.log("userId : "+this.$store.state.userId)
      // console.log(this.$store.state.userName)
      this.$axios.post("/showwy/addfavorite", {
        aglistIds: this.favrtAglstId,
        userId: this.$store.state.userId
      })
        .then(res => {
          this.$message({
            showClose: true,
            message: '恭喜你，操作成功',
            type: 'success'
          });
        })
      this.multipleSelection = []
      this.favrtAglstId = []
      this.getAgInfo();
    },

    getAgInfo() {
      this.$axios
        .get("/showwy/aginfo", {
          params: {
            aglistId: null,
            code: this.searchForm.code,
            stype: null,
            hsgt: null,
            bk: null,

            current: this.current,
            size: this.size
          }
        })
        .then(res => {
          console.log("==========+++++++===========");
          console.log(res.data.data);
          console.log("==========+++++++===========");
          this.tableData = res.data.data.records;
          this.size = res.data.data.size;
          this.current = res.data.data.current;
          this.total = res.data.data.total;
        });
    },

    // addGpHq(code) {
    //   // console.log('********************************************');
    //   // console.log(code);
    //    this.$axios.post("/showwy/renewdatabycode", {
    //           code:code, 
    //           isDelFile:'false'
    //     })
    //     .then(res => {
    //       this.$message({
    //         showClose: true,
    //         message: '恭喜你，操作成功',
    //         type: 'success',
    //         onClose:() => {
    //           this.getAgInfo();
    //         }
    //       });
    //     })  
    // },

    addGpHq(code) {
      this.$confirm("是否确实要加入行情？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      }).then(() => {
        //alert("你点击了确定！" + code);
        this.$axios.post("/showwy/renewdatabycode", {
          code: code,
          isDelFile: 'false'
        })
        .then(res => {
            this.$message({
              showClose: true,
              message: '恭喜你，操作成功',
              type: 'success',
              onClose: () => {
                this.getAgInfo();
              }
            });
          })
      }).catch(() => {
        //alert("你点击了取消！" + code);
      })
    },

    //#region
    // submitForm(formName) {
    // 	this.$refs[formName].validate((valid) => {
    // 		if (valid) {
    // 			this.$axios.post('/sys/user/' + (this.editForm.id ? 'update' : 'save'), this.editForm)
    // 				.then(res => {

    // 					this.$message({
    // 						showClose: true,
    // 						message: '恭喜你，操作成功',
    // 						type: 'success',
    // 						onClose: () => {
    // 							this.getAgInfo()
    // 						}
    // 					});

    // 					this.dialogVisible = false
    // 				})
    // 		} else {
    // 			console.log('error submit!!');
    // 			return false;
    // 		}
    // 	});
    // },
    // editHandle(id) {
    // 	this.$axios.get('/sys/user/info/' + id).then(res => {
    // 		this.editForm = res.data.data

    // 		this.dialogVisible = true
    // 	})
    // },
    // delHandle(id) {
    // 	var ids = []
    // 	if (id) {
    // 		ids.push(id)
    // 	} else {
    // 		this.multipleSelection.forEach(row => {
    // 			ids.push(row.id)
    // 		})
    // 	}
    // 	console.log(ids)

    // 	this.$axios.post("/sys/user/delete", ids).then(res => {
    // 		this.$message({
    // 			showClose: true,
    // 			message: '恭喜你，操作成功',
    // 			type: 'success',
    // 			onClose: () => {
    // 				this.getAgInfo()
    // 			}
    // 		});
    // 	})
    // },

    // submitRoleHandle(formName) {
    // 	var roleIds = this.$refs.roleTree.getCheckedKeys()

    // 	console.log(roleIds)

    // 	this.$axios.post('/sys/user/role/' + this.roleForm.id, roleIds).then(res => {
    // 		this.$message({
    // 			showClose: true,
    // 			message: '恭喜你，操作成功',
    // 			type: 'success',
    // 			onClose: () => {
    // 				this.getAgInfo()
    // 			}
    // 		});

    // 		this.roleDialogFormVisible = false
    // 	})
    // },
    // #endregion
    cjeFormatter(row, column, cellValue, index) {
      return row.cje / 1000;
    }
  }
};
</script>

<style scoped>
/* .el-form{
		margin-top: 2px;
		margin-bottom: 2px;
		padding: 0px;
		height: 30px;
	}
	.el-form-item{
		padding: 0px;
		height: 20px;
	} */

/* #search{
		margin-top: 0;
		margin-bottom: 0;
		padding: 0px;
		height: 20px;
	} */
.el-table /deep/ td {
  padding: 0;
}

.el-pagination {
  float: right;
  margin-top: 5px;
  margin-bottom: 5px;
}

.search-container /deep/ .el-form-item {
  margin-bottom: 2px;
}

.search-container /deep/ .el-input__inner {
  height: 26px;
  line-height: 26px;
}

.search-container /deep/ .el-button {
  padding: 5px 12px;
}

.mini-table-container {
  width: 100%;
  height: calc(100% - 36px);
}
</style>